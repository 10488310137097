/* LAYOUTGROUPS */
export const GET_LAYOUTGROUPS = 'GET_LAYOUTGROUPS';
export const GET_LAYOUTGROUPS_SUCCESS = 'GET_LAYOUTGROUPS_SUCCESS';
export const GET_LAYOUTGROUPS_FAIL = 'GET_LAYOUTGROUPS_FAIL';

export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export const GET_COLLECTIONS_SUCCESS = 'GET_COLLECTIONS_SUCCESS';
export const GET_COLLECTIONS_FAIL = 'GET_COLLECTIONS_FAIL';

export const GET_SINGLE_COLLECTION = 'GET_SINGLE_COLLECTION';
export const GET_SINGLE_COLLECTION_SUCCESS = 'GET_SINGLE_COLLECTION_SUCCESS';
export const GET_SINGLE_COLLECTION_FAIL = 'GET_SINGLE_COLLECTION_FAIL';
