import { addOrRemoveFav } from 'store/actions';
import storage from 'util/localStorage';

const useWishlistAction = (dispatch, favId) => {
  dispatch(addOrRemoveFav(favId));

  const user_favourite =
    storage.get('user_favourite') !== null && storage.get('user_favourite');

  if (user_favourite?.length >= 1 && user_favourite?.includes(favId) === true) {
    const filterFavItmes = user_favourite?.filter((i) => i !== favId);
    storage.set('user_favourite', filterFavItmes);
  } else {
    if (user_favourite?.length >= 1) {
      const newFavItems = [...user_favourite, favId];
      storage.set('user_favourite', newFavItems);
    } else {
      storage.set('user_favourite', [favId]);
    }
  }
};

export default useWishlistAction;

export const useLocalWishlist = () => {
  return storage.get('user_favourite');
};
