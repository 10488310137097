import {
  LOGIN_USER_MOBILE_SUCCESS,
  LOGIN_USER_MOBILE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILD,
  LOGIN_USER_MOBILE_FAILD,
  LOGIN_USER_OTP,
  LOGIN_USER_OTP_SUCCESS,
  LOGIN_USER_OTP_FAILD,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILD,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILD,
} from './actionTypes';

const initialState = {
  error: '',
  loading: false,
  user: [],
  otp: {},
  user1: {},
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_MOBILE:
    case LOGIN_USER_OTP:
    case GET_USER:
    case UPDATE_USER_INFO:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_USER_MOBILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        error: '',
        otp: {},
      };
      break;
    case LOGIN_USER_MOBILE_FAILD:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case GET_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
      };
      break;
    case GET_USER_FAILD:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case LOGIN_USER_OTP_SUCCESS:
      state = {
        ...state,
        loading: false,
        otp: action.payload,
        error: '',
      };
      break;
    case LOGIN_USER_OTP_FAILD:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case UPDATE_USER_INFO_SUCCESS:
      state = { ...state, loading: false, user1: action.payload, error: '' };
      break;
    case UPDATE_USER_INFO_FAILD:
      state = { ...state, error: action.payload, loading: false };
      break;

    case LOGOUT_USER:
      state = { ...state, loading: true };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, loading: false, user: [], error: '' };
      break;
    case LOGOUT_USER_FAILD:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
