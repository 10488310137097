import { map } from 'lodash';
import {
  addOrRemove,
  showCartNotificationAction,
  showToasterAction,
} from 'store/actions';
import storage from 'util/localStorage';

const useCartActions = (options, dispatch, product) => {
  const oldCartitems =
    storage.get('user-cart-item') !== null && storage.get('user-cart-item');

  if (typeof oldCartitems === 'string') {
    storage.set('user-cart-item', []);
    return;
  }

  const token = localStorage.getItem('babyday_token');

  const currentVarient = product?.varients?.filter(
    (v) => v._id === options.varient,
  );

  const productModal = {
    primaryLang: product?.primaryLang,
    secondaryLang: product?.secondaryLang,
    images: product?.images?.slice(0, 1),
    _id: product?._id,
    varients: currentVarient ? currentVarient : [],
    hasVarients: product?.hasVarients,
  };

  const cartItem = {
    ...options,
    product: productModal,
    varient: currentVarient[0]?._id,
    varientValue: currentVarient[0]?.value,
    varientUnit: currentVarient[0]?.unit,
    price: product?.hasVarients ? currentVarient[0]?.price : product?.price,
    offerPrice: product?.offerPrice,
    quantity: options?.quantity ? options?.quantity : 1,
    _id: product?._id,
    stock: product?.stock ? product?.stock : 2,
  };

  const cartItemIds = oldCartitems && oldCartitems?.map((i) => i._id);
  const quantity = options?.quantity ? options?.quantity : 1;

  const handleCartQty = (operation, qty, stock) => {
    switch (operation) {
      case 'add':
        if (qty < stock) {
          dispatch(
            showToasterAction({
              data: { title: 'Cart', message: 'Cart Updated' },
              type: 'success',
            }),
          );
          return qty + quantity;
        } else {
          dispatch(
            showToasterAction({
              data: {
                title: 'Cart',
                message: 'Such Quantity is not available',
              },
              type: 'warning',
            }),
          );
          return qty;
        }
      case 'substract':
        dispatch(
          showToasterAction({
            data: { title: 'Cart', message: 'Cart Updated' },
            type: 'success',
          }),
        );
        return qty - quantity;
      default:
        return qty;
    }
  };

  const newItem = map(oldCartitems, (item) => {
    if (item?._id === cartItem?._id) {
      return {
        ...item,
        quantity: handleCartQty(
          options?.operation,
          item?.quantity,
          item?.stock,
        ),
      };
    } else {
      return item;
    }
  });

  if (token) {
    dispatch(addOrRemove(options));
  } else {
    if (oldCartitems) {
      storage.set(
        'user-cart-item',
        cartItemIds?.includes(product?._id) === true
          ? newItem
          : [...newItem, cartItem],
      );
      dispatch(showCartNotificationAction());
    } else {
      storage.set('user-cart-item', [cartItem]);
      dispatch(
        showToasterAction({
          data: { title: 'Cart', message: 'Cart Updated' },
          type: 'success',
        }),
      );
      dispatch(showCartNotificationAction());
    }
  }
};

export default useCartActions;

export const useLocalCart = () => {
  return storage.get('user-cart-item');
};

export const clearLocalCart = () => {
  return storage.delete('user-cart-item');
};

export const useLocalCartPrices = () => {
  const localCart = useLocalCart();

  if (typeof localCart === 'string') {
    storage.set('user-cart-item', []);
    const itemTotal = 0;
    return {
      itemTotal,
    };
  } else {
    const localCartSum =
      localCart?.length >= 1 &&
      localCart?.map(
        (i) => (i?.offerPrice ? i?.offerPrice : i?.price) * i?.quantity,
      );

    const itemTotal = localCartSum && localCartSum?.reduce((x, y) => x + y, 0);

    return {
      itemTotal,
    };
  }
};
