/* LAYOUT */
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_LAYOUT_WIDTH = 'CHANGE_LAYOUT_WIDTH';
export const CHANGE_SIDEBAR_THEME = 'CHANGE_SIDEBAR_THEME';
export const CHANGE_SIDEBAR_THEME_IMAGE = 'CHANGE_SIDEBAR_THEME_IMAGE';
export const CHANGE_SIDEBAR_TYPE = 'CHANGE_SIDEBAR_TYPE';

// topbar
export const CHANGE_TOPBAR_THEME = 'CHANGE_TOPBAR_THEME';

// show sidebar
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const TOGGLE_LEFTMENU = 'TOGGLE_LEFTMENU';

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = 'SHOW_RIGHT_SIDEBAR';

// Preloader
export const CHANGE_PRELOADER = 'CHANGE_PRELOADER';

//login
export const SHOW_LOGIN_POPUP = 'SHOW_LOGIN_POPUP';
export const HIDE_LOGIN_POPUP = 'HIDE_LOGIN_POPUP';

//notification toastr
export const SHOW_TOASTER = 'SHOW_TOASTER';
export const HIDE_TOASTER = 'HIDE_TOASTER';

export const SHOW_LOCATION_POPUP = 'SHOW_LOCATION_POPUP';
export const HIDE_LOCATION_POPUP = 'HIDE_LOCATION_POPUP';

export const SET_SITE_LANGUAGE = 'SET_SITE_LANGUAGE';

export const SHOW_CART_NOTIFICATION = 'SHOW_CART_NOTIFICATION';
export const HIDE_CART_NOTIFICATION = 'HIDE_CART_NOTIFICATION';

export const SHOW_AREAS_LIST = 'SHOW_AREAS_LIST';
export const HIDE_AREAS_LIST = 'HIDE_AREAS_LIST';

export const HIDE_MOBILE_NAV = 'HIDE_MOBILE_NAV';
export const SHOW_MOBILE_NAV = 'SHOW_MOBILE_NAV';
