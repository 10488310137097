import axios from 'axios';

const API_URL_PROD = 'https://api.babyday.in';
const API_URL_DEV = 'http://192.168.1.33:4000';

export const FRONT_END_URL = 'https://www.babyday.in';
export const API_URL = API_URL_PROD;

export const axiosApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axiosApi.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('babyday_token');

    if (token) {
      config.headers['Authorization'] =
        'Bearer ' + localStorage.getItem('babyday_token');
    }

    return config;
  },
  function (error) {},
);

axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // window.history.replaceState({}, "", "/500")
    return error.response;
  },
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function ApiPut(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
