import {
  GET_LAYOUTPAGES,
  GET_LAYOUTPAGES_FAIL,
  GET_LAYOUTPAGES_SUCCESS,
  GET_PAGES,
  GET_PAGES_FAIL,
  GET_PAGES_HOME,
  GET_PAGES_HOME_FAIL,
  GET_PAGES_HOME_SUCCESS,
  GET_PAGES_SUCCESS,
  GET_SINGLE_PAGE,
  GET_SINGLE_PAGE_FAIL,
  GET_SINGLE_PAGE_SUCCESS,
} from './actionTypes';

export const getLayoutPages = (name) => ({
  type: GET_LAYOUTPAGES,
  payload: { name },
});

export const getLayoutPagesSuccess = (pageData) => ({
  type: GET_LAYOUTPAGES_SUCCESS,
  payload: pageData,
});
export const getLayoutPagesFail = (error) => ({
  type: GET_LAYOUTPAGES_FAIL,
  payload: error,
});

export const getPagesHome = () => ({
  type: GET_PAGES_HOME,
});

export const getPagesHomeSuccess = (pageData) => ({
  type: GET_PAGES_HOME_SUCCESS,
  payload: pageData,
});
export const getPagesHomeFail = (error) => ({
  type: GET_PAGES_HOME_FAIL,
  payload: error,
});

//all pages
export const getPages = (page, limit) => ({
  type: GET_PAGES,
  payload: { page, limit },
});

export const getPagesSuccess = (pages) => ({
  type: GET_PAGES_SUCCESS,
  payload: pages,
});

export const getPagesFail = (error) => ({
  type: GET_PAGES_FAIL,
  payload: error,
});

//all SINGLE page
export const getSinglePage = (pageId) => ({
  type: GET_SINGLE_PAGE,
  payload: pageId,
});

export const getSinglePageSuccess = (pageDetails) => ({
  type: GET_SINGLE_PAGE_SUCCESS,
  payload: pageDetails,
});

export const getSinglePageFail = (error) => ({
  type: GET_SINGLE_PAGE_FAIL,
  payload: error,
});
