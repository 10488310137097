import { useLocation } from 'react-router-dom';
import { useArrayToString } from './useArrayToString';
import { useHtmlToString } from './useHtmlToString';

const useMetaHandler = (title, description, keywords, type) => {
  const location = useLocation();
  const isHtml = /<\/?[a-z][\s\S]*>/i.test(description);
  const isArray = Array.isArray(keywords);

  return {
    canonicalLink: location.pathname,
    metaTitle: title && title?.slice(0, 55),
    metaDescription: isHtml
      ? useHtmlToString(description)?.slice(0, 170)
      : description?.slice(0, 170),
    metaKeyword: isArray ? useArrayToString(keywords) : keywords,
  };
};
export default useMetaHandler;
