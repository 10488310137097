import { getLocalCart } from 'store/actions';
import useCartActions from './useCartAction';
import { useRemoveSpace } from './useRemoveSpace';

export const useCallToAction = (product, dispatch, type, stock, history) => {
  const token = localStorage.getItem('toysinside_token');

  switch (type) {
    case 'product':
      if (product?.primaryLang?.name?.length > 0) {
        history.push(`/products/${useRemoveSpace(product?.primaryLang?.name)}`);
      }
      break;
    case 'checkOut':
    case 'cart':
      const options = {
        product: product?._id,
        varient: product?.varients[0]?._id,
        quantity: 1,
        operation: 'add',
      };
      if (stock >= 1) {
        useCartActions(options, dispatch, { ...product, stock: stock });
        if (!token) {
          dispatch(getLocalCart());
        }
      }
      if (type === 'checkOut') {
        history.push(`/cart`);
      }
      break;
  }
};
