import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { GET_PAYMENT_KEY, GET_SITE_SETTINGS } from './actionTypes';
import {
  getSiteSettingsSuccess,
  getSiteSettingsFail,
  getPaymentKeySuccess,
  getPaymentKeyFail,
} from './actions';
import { get } from 'helpers/api_methods';

function getSettingsAPi() {
  return get('/settings/custom/all');
}
function getPaymentKeyAPi() {
  return get('/settings/custom/creditionals');
}

function* fetchSettings() {
  try {
    const response = yield call(getSettingsAPi);

    yield put(getSiteSettingsSuccess(response));
  } catch (error) {
    yield put(getSiteSettingsFail(error));
  }
}

function* fetchPaymentKey() {
  try {
    const response = yield call(getPaymentKeyAPi);
    yield put(getPaymentKeySuccess(response));
  } catch (error) {
    yield put(getPaymentKeyFail(error));
  }
}

function* siteSettingsSaga() {
  yield takeEvery(GET_SITE_SETTINGS, fetchSettings);
  yield takeEvery(GET_PAYMENT_KEY, fetchPaymentKey);
}

export default siteSettingsSaga;
