import {
  GET_SITE_SETTINGS,
  GET_SITE_SETTINGS_SUCCESS,
  GET_SITE_SETTINGS_FAIL,
  GET_PAYMENT_KEY,
  GET_PAYMENT_KEY_SUCCESS,
  GET_PAYMENT_KEY_FAIL,
} from './actionTypes';

export const getSiteSettings = () => ({
  type: GET_SITE_SETTINGS,
});

export const getSiteSettingsSuccess = (settings) => ({
  type: GET_SITE_SETTINGS_SUCCESS,
  payload: settings,
});

export const getSiteSettingsFail = (error) => ({
  type: GET_SITE_SETTINGS_FAIL,
  payload: error,
});

export const getPaymentKey = () => ({
  type: GET_PAYMENT_KEY,
});

export const getPaymentKeySuccess = (settings) => ({
  type: GET_PAYMENT_KEY_SUCCESS,
  payload: settings,
});

export const getPaymentKeyFail = (error) => ({
  type: GET_PAYMENT_KEY_FAIL,
  payload: error,
});
