function getDifferenceInDays(dateA, dateB) {
  const date1 = new Date(dateA);
  const date2 = new Date(dateB);

  function getDifferenceInDays2(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }

  return getDifferenceInDays2(date1, date2);
}

export default getDifferenceInDays;
