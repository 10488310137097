import React from 'react';

import loading from '../../assets/imgs/theme/loading.gif';
import LazyLoad from 'react-lazyload';

const Preloader = () => {
  return (
    <>
      <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="text-center">
              <LazyLoad>
                <img
                  src={loading}
                  alt="babyDay-loading"
                  height={'140px'}
                  className="p-1"
                  width={140}
                  title="Loading-babbyday"
                  loading="eager"
                />
              </LazyLoad>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preloader;
