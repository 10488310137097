import {
  GET_LAYOUTGROUPS,
  GET_LAYOUTGROUPS_SUCCESS,
  GET_LAYOUTGROUPS_FAIL,
  GET_COLLECTIONS,
  GET_COLLECTIONS_SUCCESS,
  GET_COLLECTIONS_FAIL,
  GET_SINGLE_COLLECTION,
  GET_SINGLE_COLLECTION_SUCCESS,
  GET_SINGLE_COLLECTION_FAIL,
} from './actionTypes';

const INIT_STATE = {
  layoutGroups: [],
  error: {},
  loading: false,
  collections: [],
  collectionLoad: false,
  singleCollection: {},
};

const LayoutGroups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LAYOUTGROUPS:
      return {
        ...state,
        loading: true,
      };
    case GET_LAYOUTGROUPS_SUCCESS:
      return {
        ...state,
        layoutGroups: action.payload,
        loading: false,
      };

    case GET_LAYOUTGROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_COLLECTIONS:
    case GET_SINGLE_COLLECTION:
      return {
        ...state,
        collectionLoad: true,
      };
    case GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: action.payload,
        collectionLoad: false,
      };

    case GET_COLLECTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        collectionLoad: false,
      };

    case GET_SINGLE_COLLECTION_SUCCESS:
      return {
        ...state,
        singleCollection: action.payload,
        collectionLoad: false,
      };

    case GET_SINGLE_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        collectionLoad: false,
      };

    default:
      return state;
  }
};

export default LayoutGroups;
