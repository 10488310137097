import {
  GET_BANNERS,
  GET_BANNERS_FAIL,
  GET_BANNERS_SUCCESS,
} from './actionTypes';

export const getBanners = () => ({
  type: GET_BANNERS,
});

export const getBannersSuccess = (bannerData) => ({
  type: GET_BANNERS_SUCCESS,
  payload: bannerData,
});
export const getBannersFail = (error) => ({
  type: GET_BANNERS_FAIL,
  payload: error,
});
