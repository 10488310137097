import { useSelector } from 'react-redux';

const usePrice = (product, selectedVarient) => {
  const firstVarient = product?.varients && product?.varients[0];

  const varient = selectedVarient ? selectedVarient : firstVarient;

  const { defaultCurrency, defaultCurrencySymbol, shipping, user } =
    useSelector((state) => ({
      defaultCurrency: state?.SiteSettings.siteSettings?.defaultCurrency,
      defaultCurrencySymbol:
        state?.SiteSettings.siteSettings?.defaultCurrencySymbol,
      shipping: state?.SiteSettings.siteSettings?.shipping,
      user: state.Login.user,
    }));

  const isPrime = user?.isPrime;

  const priceObj = {
    price: 0,
    currencyCode: defaultCurrencySymbol ?? '',
    oldPrice: varient?.price ?? 0,
    stock: varient?.stock ?? 0,
    currentVarient: `${varient?.value} ${varient?.unit}`,
    returnText: 'item return with in ' + shipping?.returnDuration + ' days',
    currency: defaultCurrency,
  };

  const handlePrice = () => {
    if (isPrime === true) {
      return product?.primePrice;
    } else if (product?.offerPrice) {
      return product?.offerPrice;
    } else {
      return product?.price;
    }
  };

  if (product?.hasVarients === true) {
    if (isPrime === true && varient?.primePrice > 0) {
      return {
        ...priceObj,
        price: varient?.primePrice,
      };
    } else {
      if (varient?.offerPrice > 0) {
        return {
          ...priceObj,
          price: varient?.offerPrice,
        };
      } else {
        return {
          ...priceObj,
          price: varient?.price,
          oldPrice: '',
        };
      }
    }
  } else {
    return {
      ...priceObj,
      price: handlePrice(),
      oldPrice: product?.price > handlePrice() ? product?.price : '',
      stock: product?.stock,
      currentVarient: '',
    };
  }
};

export default usePrice;
