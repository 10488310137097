import React from 'react';
import { MetaTags } from 'react-meta-tags';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { LogoSm } from 'assets/imgs/logos';
import { FRONT_END_URL } from 'helpers/api_methods';

const MetaData = ({
  title,
  content,
  keyword,
  description,
  canonicalLink,
  ogImage,
}) => {
  const { company } = useSelector((state) => ({
    company: state?.SiteSettings.siteSettings?.company,
  }));

  return (
    <MetaTags>
      <title>{`${title} | ${company?.name ?? 'babyday'} `}</title>
      <link rel="canonical" href={canonicalLink ? canonicalLink : '/'} />
      <link rel="icon" href={LogoSm} />
      <meta property="og:title" content={content} />
      <meta property="og:description" content={description} />
      <meta name="description" content={description} />
      <meta property="og:type" content="ecommerce" />
      <meta
        property="og:url"
        content={`${FRONT_END_URL}${canonicalLink && canonicalLink}`}
      />
      <meta property="og:image" content={ogImage ?? LogoSm} />
    </MetaTags>
  );
};

export default MetaData;

MetaData.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  keyword: PropTypes.string,
  description: PropTypes.string,
  canonicalLink: PropTypes.string,
  ogImage: PropTypes.string,
};
