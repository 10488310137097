import {
  //cart
  ADD_OR_REMOVE,
  ADD_OR_REMOVE_FAILD,
  ADD_OR_REMOVE_SUCCESS,
  GET_USER_CART,
  GET_USER_CART_FAIL,
  GET_USER_CART_SUCCESS,

  //coupon
  COUPON_VALIDATE,
  COUPON_VALIDATE_SUCCESS,
  COUPON_VALIDATE_FAIL,

  //order
  MAKE_ORDER,
  MAKE_ORDER_SUCCESS,
  MAKE_ORDER_FAIL,
  GET_USER_ORDERS,
  GET_USER_ORDERS_SUCCESS,
  GET_USER_ORDERS_FAIL,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAIL,
  RE_ORDER,
  RE_ORDER_SUCCESS,
  RE_ORDER_FAIL,
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
  RETURN_ORDER,
  RETURN_ORDER_SUCCESS,
  RETURN_ORDER_FAIL,

  //payment
  CREATE_RAZORPAY_ORDER,
  CREATE_RAZORPAY_ORDER_SUCCESS,
  CREATE_RAZORPAY_ORDER_FAIL,
  VERIFY_RAZORPAY_ORDER,
  VERIFY_RAZORPAY_ORDER_SUCCESS,
  VERIFY_RAZORPAY_ORDER_FAIL,

  //address
  GET_USER_ADDRESS,
  GET_USER_ADDRESS_SUCCESS,
  GET_USER_ADDRESS_FAIL,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_ADDRESS_SUCCESS,
  UPDATE_USER_ADDRESS_FAIL,
  DELETE_USER_ADDRESS,
  DELETE_USER_ADDRESS_SUCCESS,
  DELETE_USER_ADDRESS_FAIL,
  SET_DEFUALT_ADDRESS,
  SET_DEFUALT_ADDRESS_SUCCESS,
  SET_DEFUALT_ADDRESS_FAIL,

  //favourite
  GET_FAVOURITE_ITEMS,
  GET_FAVOURITE_ITEMS_SUCCESS,
  GET_FAVOURITE_ITEMS_FAIL,
  ADD_OR_REMOVE_FAV,
  ADD_OR_REMOVE_FAV_SUCCESS,
  ADD_OR_REMOVE_FAV_FAILD,

  //quick purchase
  GET_QUICKLIST_ITEMS,
  GET_QUICKLIST_ITEMS_SUCCESS,
  GET_QUICKLIST_ITEMS_FAIL,
  GET_LOCAL_CART,
  GET_LOCAL_CART_SUCCESS,
  GET_LOCAL_CART_FAIL,
  BULK_CART,
  BULK_CART_ADDED,
  BULK_CART_FAIL,
  GET_USER_REVIEWS,
  GET_USER_REVIEWS_SUCCESS,
  GET_USER_REVIEWS_FAIL,
  DELETE_USER_REVIEW,
  DELETE_USER_REVIEW_SUCCESS,
  DELETE_USER_REVIEW_FAIL,
  CLEAR_ORDER_STATE,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  CLEAR_ERROR,
} from './actionTypes';

//cart
export const getUserCart = () => ({
  type: GET_USER_CART,
});
export const getUserCartSuccess = (userCart) => ({
  type: GET_USER_CART_SUCCESS,
  payload: userCart,
});
export const getUserCartFail = (error) => ({
  type: GET_USER_CART_FAIL,
  payload: error,
});

export const getLocalCart = () => ({
  type: GET_LOCAL_CART,
});
export const getLocalCartSuccess = (userCart) => ({
  type: GET_LOCAL_CART_SUCCESS,
  payload: userCart,
});
export const getLocalCartFail = (error) => ({
  type: GET_LOCAL_CART_FAIL,
  payload: error,
});

export const bulkCart = (cartItems, history) => ({
  type: BULK_CART,
  payload: { cartItems, history },
});
export const bulkCartAdded = (userCart) => ({
  type: BULK_CART_ADDED,
  payload: userCart,
});
export const bulkCartFail = (error) => ({
  type: BULK_CART_FAIL,
  payload: error,
});

export const addOrRemove = (options) => {
  return {
    type: ADD_OR_REMOVE,
    payload: options,
  };
};
export const addOrRemoveSuccess = (cart) => {
  return {
    type: ADD_OR_REMOVE_SUCCESS,
    payload: cart,
  };
};
export const addOrRemoveError = (error) => {
  return {
    type: ADD_OR_REMOVE_FAILD,
    payload: error,
  };
};

//coupon
export const couponValidate = (coupon) => ({
  type: COUPON_VALIDATE,
  payload: coupon,
});
export const couponValidateSuccess = (couponvalue) => ({
  type: COUPON_VALIDATE_SUCCESS,
  payload: couponvalue,
});
export const couponValidateFail = (error) => ({
  type: COUPON_VALIDATE_FAIL,
  payload: error,
});

//order
export const makeUserOrder = (input, history) => ({
  type: MAKE_ORDER,
  payload: { input, history },
});
export const makeUserOrderSuccess = (order) => ({
  type: MAKE_ORDER_SUCCESS,
  payload: order,
});
export const makeUserOrderFail = (error) => ({
  type: MAKE_ORDER_FAIL,
  payload: error,
});
export const updateUserOrder = (input, history) => ({
  type: UPDATE_ORDER,
  payload: { input, history },
});
export const updateUserOrderSuccess = (order) => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order,
});
export const updateUserOrderFail = (error) => ({
  type: UPDATE_ORDER_FAIL,
  payload: error,
});
export const clearOrderState = () => ({
  type: CLEAR_ORDER_STATE,
});
export const getUserOrders = (page, limit) => ({
  type: GET_USER_ORDERS,
  payload: { page, limit },
});
export const getUserOrdersSuccess = (userOrders) => ({
  type: GET_USER_ORDERS_SUCCESS,
  payload: userOrders,
});
export const getUserOrdersFail = (error) => ({
  type: GET_USER_ORDERS_FAIL,
  payload: error,
});
export const getOrderDetails = (orderId) => ({
  type: GET_ORDER_DETAILS,
  payload: orderId,
});
export const getOrderDetailsSuccess = (userOrders) => ({
  type: GET_ORDER_DETAILS_SUCCESS,
  payload: userOrders,
});
export const getOrderDetailsFail = (error) => ({
  type: GET_ORDER_DETAILS_FAIL,
  payload: error,
});
export const reOrder = (orderId, history) => ({
  type: RE_ORDER,
  payload: { orderId, history },
});
export const reOrderSuccess = (order) => ({
  type: RE_ORDER_SUCCESS,
  payload: order,
});
export const reOrderFail = (error) => ({
  type: RE_ORDER_FAIL,
  payload: error,
});
export const cancelOrder = (input) => ({
  type: CANCEL_ORDER,
  payload: input,
});
export const cancelOrderSuccess = (order) => ({
  type: CANCEL_ORDER_SUCCESS,
  payload: order,
});
export const cancelOrderFail = (error) => ({
  type: CANCEL_ORDER_FAIL,
  payload: error,
});
export const returnOrder = (input) => ({
  type: RETURN_ORDER,
  payload: input,
});
export const returnOrderSuccess = (order) => ({
  type: RETURN_ORDER_SUCCESS,
  payload: order,
});
export const returnOrderFail = (error) => ({
  type: RETURN_ORDER_FAIL,
  payload: error,
});

//payment
export const createRazorpayOrder = (orderId) => ({
  type: CREATE_RAZORPAY_ORDER,
  payload: { orderId },
});
export const createRazorpayOrderSuccess = (orderDetails) => ({
  type: CREATE_RAZORPAY_ORDER_SUCCESS,
  payload: orderDetails,
});
export const createRazorpayOrderFail = (error) => ({
  type: CREATE_RAZORPAY_ORDER_FAIL,
  payload: error,
});
export const verifyRazorpayOrder = (razorpayRes) => ({
  type: VERIFY_RAZORPAY_ORDER,
  payload: { razorpayRes },
});
export const verifyRazorpayOrderSuccess = (orderDetails) => ({
  type: VERIFY_RAZORPAY_ORDER_SUCCESS,
  payload: orderDetails,
});
export const verifyRazorpayOrderFail = (error) => ({
  type: VERIFY_RAZORPAY_ORDER_FAIL,
  payload: error,
});

//address
export const getUserAddress = () => ({
  type: GET_USER_ADDRESS,
});
export const getUserAddressSuccess = (userAddress) => ({
  type: GET_USER_ADDRESS_SUCCESS,
  payload: userAddress,
});
export const getUserAddressFail = (error) => ({
  type: GET_USER_ADDRESS_FAIL,
  payload: error,
});
export const UpdateUserAddress = (input, addressId) => ({
  type: UPDATE_USER_ADDRESS,
  payload: { input, addressId },
});
export const UpdateUserAddressSuccess = (userAddress) => ({
  type: UPDATE_USER_ADDRESS_SUCCESS,
  payload: userAddress,
});
export const UpdateUserAddressFail = (error) => ({
  type: UPDATE_USER_ADDRESS_FAIL,
  payload: error,
});
export const deleteUserAddress = (addressId) => ({
  type: DELETE_USER_ADDRESS,
  payload: addressId,
});
export const deleteUserAddressSuccess = (userAddress) => ({
  type: DELETE_USER_ADDRESS_SUCCESS,
  payload: userAddress,
});
export const deleteUserAddressFail = (error) => ({
  type: DELETE_USER_ADDRESS_FAIL,
  payload: error,
});
export const setDefualtAddress = (addressId, input) => ({
  type: SET_DEFUALT_ADDRESS,
  payload: { addressId, input },
});
export const setDefualtAddressSuccess = (userAddress) => ({
  type: SET_DEFUALT_ADDRESS_SUCCESS,
  payload: userAddress,
});
export const setDefualtAddressFail = (error) => ({
  type: SET_DEFUALT_ADDRESS_FAIL,
  payload: error,
});

//faviorite
export const getFavouriteItems = (page, limit) => ({
  type: GET_FAVOURITE_ITEMS,
  payload: { page, limit },
});
export const getFavouriteItemsSuccess = (favourite) => ({
  type: GET_FAVOURITE_ITEMS_SUCCESS,
  payload: favourite,
});
export const getFavouriteItemsFail = (error) => ({
  type: GET_FAVOURITE_ITEMS_FAIL,
  payload: error,
});
export const addOrRemoveFav = (productId) => {
  return {
    type: ADD_OR_REMOVE_FAV,
    payload: productId,
  };
};
export const addOrRemoveFavSuccess = (cart) => {
  return {
    type: ADD_OR_REMOVE_FAV_SUCCESS,
    payload: cart,
  };
};
export const addOrRemoveFavError = (error) => {
  return {
    type: ADD_OR_REMOVE_FAV_FAILD,
    payload: error,
  };
};

//quiCk list
export const getQuicklist = (page, limit) => ({
  type: GET_QUICKLIST_ITEMS,
  payload: { page, limit },
});
export const getQuicklistSuccess = (favourite) => ({
  type: GET_QUICKLIST_ITEMS_SUCCESS,
  payload: favourite,
});
export const getQuicklistFail = (error) => ({
  type: GET_QUICKLIST_ITEMS_FAIL,
  payload: error,
});

//UserReviews
export const getUserReviews = (page, limit) => ({
  type: GET_USER_REVIEWS,
  payload: { page, limit },
});
export const getUserReviewsSuccess = (reviews) => ({
  type: GET_USER_REVIEWS_SUCCESS,
  payload: reviews,
});
export const getUserReviewsFail = (error) => ({
  type: GET_USER_REVIEWS_FAIL,
  payload: error,
});

export const deleteUserReview = (reviewId) => ({
  type: DELETE_USER_REVIEW,
  payload: reviewId,
});
export const deleteUserReviewSuccess = (reviews) => ({
  type: DELETE_USER_REVIEW_SUCCESS,
  payload: reviews,
});
export const deleteUserReviewFail = (error) => ({
  type: DELETE_USER_REVIEW_FAIL,
  payload: error,
});

export const clearError = (error) => ({
  type: CLEAR_ERROR,
  payload: error,
});
