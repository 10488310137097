import { useLocalCartPrices } from 'components/hooks/useCartAction';
import usePrice from 'components/hooks/usePrice';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { getLocalCart, hideCartNotificationAction } from 'store/actions';
import CartItemList from './cartItemComp2';

function CartBottomNotification() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { cartItems, localCart, userCart, cartNoti } = useSelector((state) => ({
    cartItems: state.Cart.cartAdded,
    localCart: state.Cart.localCart,
    userCart: state.Cart.userCart,
    cartNoti: state.Layout.cartNoti,
  }));
  const token = localStorage.getItem('babyday_token');

  const { currencyCode } = usePrice();

  useEffect(() => {
    if (cartNoti === true) {
      setTimeout(() => {
        dispatch(hideCartNotificationAction());
      }, 4000);
    }
  }, [cartNoti]);

  const { width } = useWindowSize();
  const handleCartItem = () => {
    if (userCart?.cartItems?.length >= 1) {
      return userCart?.cartItems;
    } else {
      return localCart?.filter((i) => i.quantity !== 0);
    }
  };

  useEffect(() => {
    if (!token) {
      dispatch(getLocalCart());
    }
  }, []);

  const { itemTotal } = useLocalCartPrices();

  return (
    <div>
      {location?.pathname === '/cart' || location?.pathname === '/checkout' ? (
        ''
      ) : (
        <div
          className={
            'd-flex h:100px w-100 p-1 position-fixed zIndex-full bg-white bottom-0 cart-bottom-noti ' +
            `${cartNoti ? 'show' : ''}`
          }
          style={{ zIndex: '99' }}
        >
          {width > 768 && (
            <>
              {handleCartItem()
                ?.slice(0, 1)
                ?.map((item, i) => (
                  <ul key={i} className="mx-2">
                    <CartItemList item={item} key={i} noti={true} />
                  </ul>
                ))}
            </>
          )}
          <div
            className={
              'w-fit d-flex align-items-center ' +
              `${width > 768 ? 'ml-auto' : 'm-auto'}`
            }
          >
            <div className="mx-4 text-center">
              <p className="mb-0 pb-0 text-black">
                {handleCartItem()?.length}&nbsp; items in your cart
                {width > 768 && <span>, Checkout Now</span>}
              </p>
              <h6>
                Subtotal :{' '}
                <Link to={'#!'} className="font-bold ">
                  {' '}
                  {currencyCode}{' '}
                  {userCart?.totalPrice ? userCart?.totalPrice : itemTotal}
                </Link>
              </h6>
            </div>
            {/* {width > 768 && (
            <Link
              to={"#!"}
              onClick={handleCheckout}
              className={`btn ${outOfStock && "bg-disable border-none"}`}
            >
              Checkout
            </Link>
          )} */}
            <Link
              to="/cart"
              className="btn bg-warning mx-2"
              style={{ opacity: '.8' }}
            >
              View cart
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default CartBottomNotification;
