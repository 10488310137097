class storage {
  static set(key, cartItems) {
    if (cartItems) {
      localStorage.setItem(key, JSON.stringify(cartItems));
    } else {
      localStorage.setItem(key, '');
    }
  }

  static get(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  static delete(key) {
    return localStorage.removeItem(key);
  }

  static clear() {
    return localStorage.clear();
  }
}

export default storage;
