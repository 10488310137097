import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import {
  GET_COLLECTIONS,
  GET_LAYOUTGROUPS,
  GET_SINGLE_COLLECTION,
} from './actionTypes';
import {
  getLayoutGroupsSuccess,
  getLayoutGroupsFail,
  getCollectionsSuccess,
  getCollectionsFail,
  getSingleCollectionSuccess,
  getSingleCollectionFail,
} from './actions';

import { get } from 'helpers/api_methods';

function getLayoutGroupApi({ page, platform, limit }) {
  return get(
    `/group/user/all?platform=${platform ? platform : 'web'}&limit=${
      limit ? limit : ''
    }&page=${page ? page : 1}`,
  );
}

function getCollectionApi() {
  return get(`/collection/user/all`);
}

function getSingleCollectionApi({ collectionName }) {
  return get(`/collection/user/name/${collectionName}`);
}

function* fetchLayoutGroups({ payload }) {
  try {
    const response = yield call(getLayoutGroupApi, payload);
    yield put(getLayoutGroupsSuccess(response));
  } catch (error) {
    yield put(getLayoutGroupsFail(error));
  }
}
function* fetchCollections({ payload }) {
  try {
    const response = yield call(getCollectionApi, payload);
    yield put(getCollectionsSuccess(response));
  } catch (error) {
    yield put(getCollectionsFail(error));
  }
}
function* fetchSingleCollections({ payload }) {
  try {
    const response = yield call(getSingleCollectionApi, payload);
    yield put(getSingleCollectionSuccess(response));
  } catch (error) {
    yield put(getSingleCollectionFail(error));
  }
}

function* layoutGroupSaga() {
  yield takeEvery(GET_LAYOUTGROUPS, fetchLayoutGroups);
  yield takeEvery(GET_COLLECTIONS, fetchCollections);
  yield takeEvery(GET_SINGLE_COLLECTION, fetchSingleCollections);
}

export default layoutGroupSaga;
