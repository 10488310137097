import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TiTick } from 'react-icons/ti';
import { RiErrorWarningFill } from 'react-icons/ri';
import { FcInfo } from 'react-icons/fc';
import { hideToasterAction } from 'store/actions';
import { useWindowSize } from 'react-use';

export function Notification() {
  const dispatch = useDispatch();

  const { notification } = useSelector((state) => ({
    notification: state.Layout.notification,
  }));

  const notificationTypes = () => {
    if (notification?.type === 'success')
      return {
        icon: <TiTick color="#fff" size={'1.7rem'} />,
        color: '#3ab87d',
      };
    if (notification?.type === 'warning')
      return {
        icon: <RiErrorWarningFill color="#fff" size={'1.7rem'} />,
        color: '#ff4444',
      };
    if (notification?.type === 'info')
      return {
        icon: <FcInfo color="#fff" size={'1.7rem'} />,
        color: '#33b5e5',
      };
    if (notification?.type === 'primary')
      return {
        icon: <FcInfo color="#fff" size={'1.7rem'} />,
        color: '#4285F4',
      };
    if (notification?.type === 'default') {
      return {
        icon: <RiErrorWarningFill color="#fff" size={'1.7rem'} />,
        color: '#2BBBAD',
      };
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (notification?.status === true) {
      setTimeout(() => {
        dispatch(hideToasterAction());
      }, 3000);
    }
  }, [notification]);

  const { width } = useWindowSize();

  return (
    <>
      {notification?.status === true && (
        <div
          className={'modal notification_modal fade custom-modal show d-block'}
          id="notification_modal"
          style={{
            height: '150px',
          }}
        >
          <div
            className={`modal-dialog ${
              width > 480
                ? notification?.status === true
                  ? `${notification?.position ?? 'topRight'}`
                  : `${notification?.position ?? 'topRight'} hide`
                : notification?.status === true
                ? 'center'
                : 'center hide'
            }`}
          >
            <div
              className="modal-content"
              style={{ background: notificationTypes()?.color }}
            >
              <div
                className="modal-body"
                style={{ background: notificationTypes()?.color }}
              >
                <div
                  className="mainNotification deal min-h-auto sm:bg-image-none d-flex align-items-center"
                  style={{ background: notificationTypes()?.color }}
                >
                  <div className="w-auto ml-0.5rem">
                    {notificationTypes().icon}
                  </div>
                  <div className="deal-top w-fit ml-1rem">
                    <h6 className="text-white">
                      {notification?.data?.title
                        ? notification?.data?.title
                        : notification?.type}
                    </h6>
                    <div
                      className="deal-content detail-info text-white line-h-16px"
                      style={{ maxWidth: '220px' }}
                    >
                      {notification?.data?.message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
