import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_MAIN_CATEGORIES_SUCCESS,
  GET_MAIN_GROUPED_CATEGORIES_SUCCESS,
  GET_ALL_GROUPED_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SINGLE_CATEGORY,
  GET_SINGLE_CATEGORY_SUCCESS,
  GET_SINGLE_CATEGORY_FAIL,
  GET_CATEGORY_NESTEDLOOP,
  GET_CATEGORY_NESTEDLOOP_SUCCESS,
  GET_CATEGORY_NESTEDLOOP_FAIL,
  SHOW_ALL_CATEGORIES_SUCCESS,
} from './actionTypes';

export const getCategories = (page, type, limit, listAll) => ({
  type: GET_CATEGORIES,
  payload: { page, type, limit, listAll },
});

export const getAllCategoriesSuccess = (category) => ({
  type: GET_ALL_CATEGORIES_SUCCESS,
  payload: category,
});
export const getGropedCategoriesSuccess = (category) => ({
  type: GET_ALL_GROUPED_CATEGORIES_SUCCESS,
  payload: category,
});

export const getMainCategoriesSuccess = (category) => ({
  type: GET_MAIN_CATEGORIES_SUCCESS,
  payload: category,
});
export const getSubCategoriesSuccess = (category) => ({
  type: GET_SUB_CATEGORIES_SUCCESS,
  payload: category,
});
export const getMainGroupedCategoriesSuccess = (category) => ({
  type: GET_MAIN_GROUPED_CATEGORIES_SUCCESS,
  payload: category,
});

export const getCategoriesFail = (error) => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
});

export const getSingleCategory = (cat) => ({
  type: GET_SINGLE_CATEGORY,
  payload: cat,
});

export const getSingleCategorySuccess = (cat) => ({
  type: GET_SINGLE_CATEGORY_SUCCESS,
  payload: cat,
});

export const getSingleCategoryFail = (error) => ({
  type: GET_SINGLE_CATEGORY_FAIL,
  payload: error,
});

export const getNestedCategory = (catId, limit) => ({
  type: GET_CATEGORY_NESTEDLOOP,
  payload: { catId, limit },
});

export const getNestedCategorySuccess = (data) => ({
  type: GET_CATEGORY_NESTEDLOOP_SUCCESS,
  payload: data,
});

export const getNestedCategoryFail = (error) => ({
  type: GET_CATEGORY_NESTEDLOOP_FAIL,
  payload: error,
});

export const showAllCategoriesSuccess = (category) => ({
  type: SHOW_ALL_CATEGORIES_SUCCESS,
  payload: category,
});
