/* CATEGORIES */
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_ALL_GROUPED_CATEGORIES_SUCCESS =
  'GET_ALL_GROUPED_CATEGORIES_SUCCESS';
export const GET_SUB_CATEGORIES_SUCCESS = 'GET_SUB_CATEGORIES_SUCCESS';

export const GET_MAIN_CATEGORIES_SUCCESS = 'GET_MAIN_CATEGORIES_SUCCESS';
export const GET_MAIN_GROUPED_CATEGORIES_SUCCESS =
  'GET_MAIN_GROUPED_CATEGORIES_SUCCESS';

export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

export const GET_SINGLE_CATEGORY = 'GET_SINGLE_CATEGORY';
export const GET_SINGLE_CATEGORY_SUCCESS = 'GET_SINGLE_CATEGORY_SUCCESS';
export const GET_SINGLE_CATEGORY_FAIL = 'GET_SINGLE_CATEGORY_FAIL';

export const GET_CATEGORY_NESTEDLOOP = 'GET_CATEGORY_NESTEDLOOP';
export const GET_CATEGORY_NESTEDLOOP_SUCCESS =
  'GET_CATEGORY_NESTEDLOOP_SUCCESS';
export const GET_CATEGORY_NESTEDLOOP_FAIL = 'GET_CATEGORY_NESTEDLOOP_FAIL';

export const SHOW_ALL_CATEGORIES_SUCCESS = 'SHOW_ALL_CATEGORIES_SUCCESS';
