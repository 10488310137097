import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useWindowSize } from 'react-use';
import PropTypes from 'prop-types';

function CustomizedLoader({
  style,
  innerClass,
  count,
  mobileCount,
  containerClass,
  imageClass,
  imageStyle,
  type,
  imagecontainerClass,
}) {
  const { width } = useWindowSize();

  return (
    <>
      <div className="d-flex align-items-start">
        {type === 'withImg' && (
          <Skeleton
            containerClassName={'mt-4 ' + `${imagecontainerClass}`}
            circle
            className={'mb-3 m-auto ' + `${imageClass}`}
            style={imageStyle}
            count={width > 768 ? count : mobileCount}
            duration={2}
          />
        )}
        <Skeleton
          containerClassName={'d-flex mt-4 container ' + `${containerClass}`}
          count={width > 768 ? count : mobileCount}
          className={'mb-3 w-100 m-auto ' + `${innerClass}`}
          style={style}
          duration={2}
        />
      </div>
    </>
  );
}

export default CustomizedLoader;

CustomizedLoader.propTypes = {
  style: PropTypes.any,
  innerClass: PropTypes.string,
  count: PropTypes.number,
  mobileCount: PropTypes.number,
  containerClass: PropTypes.string,
  imageClass: PropTypes.any,
  imageStyle: PropTypes.any,
  type: PropTypes.string,
  imagecontainerClass: PropTypes.string,
};
